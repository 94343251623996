<template>
  <RecordViewWrapper>
    <sdPageHeader title="Direktorat">
      <template #subTitle>
        <sdButton class="btn-add_new" size="default" type="primary">
          <router-link to="/master/direktorat-add"> <sdFeatherIcons type="plus" size="14" /> Tambah</router-link>
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';

const columns = [
  {
    title: '#',
    dataIndex: 'key',
    key: 'key',
    width: '50px',
  },
  {
    title: 'Nama',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);

    onMounted(() => {
      dispatch('axiosCrudGetData', 'direktorat');
    });

    const handleDelete = (id, name) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus ${name}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'direktorat',
            getData: () => {
              dispatch('axiosCrudGetData', 'direktorat');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map(data => {
            const { id, name, description, order_id } = data;

            return {
              key: order_id,
              name,
              description,
              action: (
                <div class="table-actions">
                  <router-link class="edit" to={`/master/direktorat-edit/${id}`}>
                    <sdFeatherIcons type="edit" size={14} title="Ubah" />
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                  <router-link class="delete" onClick={() => handleDelete(id, data.name)} to="#">
                    <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
                  </router-link>
                </div>
              ),
            };
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'direktorat', filter: formState.searchItem });
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
    };
  },
};

export default ViewPage;
</script>
